/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FollowStoreArgs = {
    targetUserId: string;
    userId: string;
};
export type UseToggleStoreFollowMutationVariables = {
    args: FollowStoreArgs;
    userId: string;
};
export type UseToggleStoreFollowMutationResponse = {
    readonly followStore: {
        readonly src: {
            readonly id: string;
            readonly followsWhereIsSrcCount: number;
        } | null;
        readonly target: {
            readonly id: string;
            readonly aggregate: {
                readonly id: string;
                readonly followsWhereIsTargetCount: number;
            };
            readonly relativeToOther: {
                readonly isOtherFollowsSrc: boolean;
            } | null;
        } | null;
    };
};
export type UseToggleStoreFollowMutation = {
    readonly response: UseToggleStoreFollowMutationResponse;
    readonly variables: UseToggleStoreFollowMutationVariables;
};



/*
mutation UseToggleStoreFollowMutation(
  $args: FollowStoreArgs!
  $userId: String!
) {
  followStore(args: $args) {
    src {
      id
      followsWhereIsSrcCount
    }
    target {
      id
      aggregate {
        id
        followsWhereIsTargetCount
      }
      relativeToOther(userId: $userId) {
        isOtherFollowsSrc
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "args"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "args",
        "variableName": "args"
      }
    ],
    "concreteType": "FollowStoreResult",
    "kind": "LinkedField",
    "name": "followStore",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StoreFollowsAggregate",
        "kind": "LinkedField",
        "name": "src",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "followsWhereIsSrcCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StoreFollows",
        "kind": "LinkedField",
        "name": "target",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreFollowsAggregate",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "followsWhereIsTargetCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "userId",
                "variableName": "userId"
              }
            ],
            "concreteType": "StoreFollowsRelativeToOther",
            "kind": "LinkedField",
            "name": "relativeToOther",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOtherFollowsSrc",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UseToggleStoreFollowMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UseToggleStoreFollowMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5c4bcab550ad895b7c19927c82052734",
    "id": null,
    "metadata": {},
    "name": "UseToggleStoreFollowMutation",
    "operationKind": "mutation",
    "text": "mutation UseToggleStoreFollowMutation(\n  $args: FollowStoreArgs!\n  $userId: String!\n) {\n  followStore(args: $args) {\n    src {\n      id\n      followsWhereIsSrcCount\n    }\n    target {\n      id\n      aggregate {\n        id\n        followsWhereIsTargetCount\n      }\n      relativeToOther(userId: $userId) {\n        isOtherFollowsSrc\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c8decc0bec42c23f6f65e9e622974965';
export default node;
