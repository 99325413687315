import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {faPlus} from '@fortawesome/pro-light-svg-icons'
import {
  faBookOpen,
  faFileAlt,
  faPen,
  faStar,
} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {API} from '@indieocean/apidef'
import React, {useState} from 'react'
import ReactDOM from 'react-dom'
import {usePopper} from 'react-popper'
import {isBasicStoreLive} from '../../Common/Page/WithBasicStoreLive'
import {AppLink} from '../../Common/Tools/AppLink'
import {useUser} from '../../Common/WithUser'

export const PrimaryActionFloatMenu = React.memo(() => {
  const user = useUser()

  const [postPath, articlePath, reviewPath, bookPath] = (() => {
    if (!user || !isBasicStoreLive(user.store)) {
      return [
        API.Path.getStarted(),
        API.Path.getStarted(),
        API.Path.getStarted(),
        API.Path.getStarted(),
      ]
    }
    const storePath = API.Path.store(user.store.slug)
    return [
      storePath.addShortPost,
      storePath.addBlogPost,
      storePath.addReview,
      storePath.addBook(),
    ]
  })()

  const [open, setOpen] = useState(false)
  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      {name: 'flip', enabled: false},
      {name: 'offset', options: {offset: [0, -65]}},
    ],
  })

  return (
    <div className="absolute bottom-0 right-0 mb-6 mr-[1rem] ">
      <button
        className="flex items-center justify-center btn-dark rounded-full h-[65px] w-[65px] shadow-2xl"
        onClick={() => setOpen(true)}
        ref={setReferenceElement}
      >
        <FontAwesomeIcon className="text-4xl" icon={faPlus} />
      </button>
      {open &&
        ReactDOM.createPortal(
          <div
            className="font-mont fixed w-screen h-screen top-0 left-0 z-50 bg-pageBG bg-opacity-95"
            onClick={() => setOpen(false)}
          >
            <div
              className="flex flex-col items-end gap-y-6"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <_Button path={articlePath} label="Article" icon={faFileAlt} />
              <_Button path={reviewPath} label="Review" icon={faStar} />
              <_Button path={postPath} label="Post" icon={faPen} />
              <_Button path={bookPath} label="Book" icon={faBookOpen} />
            </div>
          </div>,
          document.body
        )}
    </div>
  )
})

export const _Button = React.memo(
  ({path, label, icon}: {path: API.Path; label: string; icon: IconProp}) => {
    return (
      <AppLink path={path} className="flex items-center gap-x-6">
        <h2 className="text-xl font-medium">{label}</h2>
        <div className="flex items-center justify-center btn-dark rounded-full h-[65px] w-[65px] shadow-2xl">
          <FontAwesomeIcon className="text-xl" icon={icon} />
        </div>
      </AppLink>
    )
  }
)
