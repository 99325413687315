import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from '@indieocean/apidef'
import { assert } from '@indieocean/utils'
import Link from 'next/link'
import React from 'react'
import { useBasicStoreLive } from '../../../../Common/Page/WithBasicStoreLive'
import { useAppPathFn } from '../../../../Common/Tools/UseAppPathFn'
import { isUserWithLiveStore, useUser } from '../../../../Common/WithUser'
import { payoutDetailsAlertData } from '../../../Account/PayoutDetails/PayoutDetailsAlertData'

export const StoreHomeStripeAlert = React.memo(
  ({className = ''}: {className?: string}) => {
    const pathFn = useAppPathFn()
    const user = useUser()
    const store = useBasicStoreLive()
    if (store.user.userId !== user?.userId) return <></>
    assert(user && isUserWithLiveStore(user))
    const alert = payoutDetailsAlertData(user.store)
    if (!alert || alert.hide) return <></>
    return (
      <Link
        href={
          alert.action === 'setupPayouts'
            ? pathFn(API.Path.account.monetize())
            : pathFn(API.Path.account.payoutDetails)
        }
      >
        <a
          className={`${className} text-sm grid justify-start items-center gap-x-4`}
          style={{grid: 'auto / auto auto'}}
        >
          <h1 className="text-errorFG  font-semibold">
            <FontAwesomeIcon className="mr-1" icon={faExclamationCircle} />
            {alert.label}
          </h1>
          <h1 className="a-btn pt-2 pb-2  underline  text-errorFG font-semibold">
            {alert.action === 'setupPayouts' ? 'Fix / Hide' : 'View'}
          </h1>
        </a>
      </Link>
    )
  }
)
