import {assert, noCase} from '@indieocean/utils'
import {UserWithLiveStore} from '../../../Common/WithUser'

export function payoutDetailsAlertData(store: UserWithLiveStore['store']): {
  label: string
  detail: string
  chargesEnabled: boolean
  action: 'setupPayouts' | 'dashboard' | 'onboard'
  actionDisplay: string
  hide: boolean
} | null {
  const {stripe} = store.data

  if (stripe.__typename === 'StripeNotCreated') {
    const hide = (() => {
      switch (stripe.reason) {
        case 'pending':
          return false
        case 'notInterested':
        case 'notUS':
          return true
        default:
          noCase(stripe.reason)
      }
    })()
    return {
      label: 'Your store is not monetized.',
      detail:
        'We need some more information to enable monetization for your store.',
      chargesEnabled: false,
      action: 'setupPayouts' as const,
      actionDisplay: 'Setup Monetization',
      hide,
    }
  } else if (stripe.__typename === 'StripeCreated') {
    if (stripe.payoutsEnabled && stripe.chargesEnabled) {
      switch (stripe.requirements) {
        case 'none':
        case 'future':
          return null
        case 'onboarding':
          assert(false)
        case 'current':
        case 'pastDue':
          return {
            label: 'Monetization information due.',
            detail:
              'Your store is currently monetized but we need some additional information to keep paying you.',

            chargesEnabled: stripe.chargesEnabled,
            action: 'dashboard' as const,
            actionDisplay: 'Provide Information',
            hide: false,
          }
        default:
          noCase(stripe.requirements)
      }
    } else if (!stripe.chargesEnabled) {
      switch (stripe.requirements) {
        case 'none':
        case 'future':
          assert(false)
        case 'onboarding':
          return {
            label: 'Monetization is incomplete.',
            detail:
              'You started the process for monetizing your store, but have some steps left.',

            chargesEnabled: stripe.chargesEnabled,
            action: 'onboard' as const,
            actionDisplay: 'Resume Monetization',
            hide: false,
          }
        case 'current':
        case 'pastDue':
          return {
            label: 'Monetization is paused.',
            detail:
              'We need some additional information to re-enable monetization.',
            chargesEnabled: stripe.chargesEnabled,
            action: 'dashboard' as const,
            actionDisplay: 'Provide Information',
            hide: false,
          }
        default:
          noCase(stripe.requirements)
      }
    } else {
      switch (stripe.requirements) {
        case 'none':
        case 'future':
        case 'onboarding':
          assert(false)
        case 'current':
        case 'pastDue':
          return {
            label: 'Payouts are paused.',
            detail: 'We need some additional information to re-enable payouts.',
            chargesEnabled: stripe.chargesEnabled,
            action: 'dashboard' as const,
            actionDisplay: 'Provide Information',
            hide: false,
          }
        default:
          noCase(stripe.requirements)
      }
    }
  } else {
    noCase(stripe)
  }
}
