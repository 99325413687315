import { API } from '@indieocean/apidef'
import { assert, fGet } from '@indieocean/utils'
import {
  ConnectionHandler,
  graphql,
  RecordProxy,
  RecordSourceSelectorProxy
} from 'relay-runtime'
import { useBasicStoreLive } from '../../../../../Common/Page/WithBasicStoreLive'
import { useCreateStoreOrAction } from '../../../../../Common/Tools/UseCreateStoreOrAction'
import { useGQLMutation } from '../../../../../Common/Tools/UseGQLMutation'
import {
  UseToggleStoreFollowMutation,
  UseToggleStoreFollowMutationResponse
} from './__generated__/UseToggleStoreFollowMutation.graphql'
import {
  UseToggleStoreFollowUndoMutation,
  UseToggleStoreFollowUndoMutationResponse
} from './__generated__/UseToggleStoreFollowUndoMutation.graphql'

const mutation = graphql`
  mutation UseToggleStoreFollowMutation(
    $args: FollowStoreArgs!
    $userId: String!
  ) {
    followStore(args: $args) {
      ...UseToggleStoreFollowFragment @relay(mask: false)
    }
  }
`

const undoMutation = graphql`
  mutation UseToggleStoreFollowUndoMutation(
    $args: FollowStoreArgs!
    $userId: String!
  ) {
    undoFollowStore(args: $args) {
      ...UseToggleStoreFollowFragment @relay(mask: false)
    }
  }
`

const fragment = graphql`
  fragment UseToggleStoreFollowFragment on FollowStoreResult {
    src {
      id
      followsWhereIsSrcCount
    }
    target {
      id
      aggregate {
        id
        followsWhereIsTargetCount
      }
      relativeToOther(userId: $userId) {
        isOtherFollowsSrc
      }
    }
  }
`

export function useToggleStoreFollow() {
  const store = useBasicStoreLive()
  const [commit] = useGQLMutation<UseToggleStoreFollowMutation>(
    mutation,
    'soft'
  )
  const [commitUndo] = useGQLMutation<UseToggleStoreFollowUndoMutation>(
    undoMutation,
    'soft'
  )

  const handleToggleFollow = useCreateStoreOrAction(user => {
    assert('relativeToOther' in store.data.follows)
    const {relativeToOther} = store.data.follows
    const variables = {
      args: {userId: user.userId, targetUserId: store.user.userId},
      userId: user.userId,
    }
    const config = (delta: 1 | -1) => {
      const responseBody:
        | UseToggleStoreFollowMutationResponse['followStore']
        | UseToggleStoreFollowUndoMutationResponse['undoFollowStore'] = {
        src: {
          id: API.GQLIds.storeFollowsAggregate({userId: user.userId}),
          followsWhereIsSrcCount:
            fGet(user.store?.data).follows.aggregate.followsWhereIsSrcCount +
            delta,
        },
        target: {
          id: API.GQLIds.storeFollows({userId: store.user.userId}),
          aggregate: {
            id: API.GQLIds.storeFollowsAggregate({userId: store.user.userId}),
            followsWhereIsTargetCount:
              store.data.follows.aggregate.followsWhereIsTargetCount + delta,
          },
          relativeToOther: {isOtherFollowsSrc: delta === 1},
        },
      }
      const optimisticResponse:
        | UseToggleStoreFollowMutationResponse
        | UseToggleStoreFollowUndoMutationResponse =
        delta === 1
          ? {followStore: responseBody}
          : {undoFollowStore: responseBody}

      // RELAY ISSUE: This updater does not work as expected. It does seem to
      // invalidate the connection, and when you navigate to the page, the data
      // seems to reload and the correct data is there in in the gql response
      // and on inspection of the store. BUT Relay still surfaces the stale data
      // over the fresh data. I can't wrap my mind around it, and it feels very
      // much like a bug in Relay. I'm leaving this here because it does not
      // harm anything, and maybe it will work in later versions of Relay. To
      // find all instances of this bug, also search for "router.reload()", that
      // is the nuclear option.
      const updater = (cache: RecordSourceSelectorProxy<unknown>) => {
        const targetFollows = cache.get(
          API.GQLIds.storeFollows({userId: store.user.userId})
        )
        const srcFollows = cache.get(
          API.GQLIds.storeFollows({userId: user.userId})
        )
        const srcStore = fGet(
          cache
            .get(API.GQLIds.store({userId: user.userId}))
            ?.getLinkedRecord('data')
        )
        const feedConnection = ConnectionHandler.getConnection(
          srcStore,
          'Home_feed'
        )
        
        // RELAY ISSUE: Even worse than the above. Invalidating feedConnection
        // seems to put relay into an infinite loop when navigating to the feed
        // page, so just leave it be.
        //
        // const connections = [feedConnection]
        const connections: (RecordProxy | null | undefined)[] = []

        if (targetFollows) {
          connections.push(
            ConnectionHandler.getConnection(targetFollows, 'Follows_list', {
              type: 'whereIsTarget',
              inTargetOf: null,
            }),
            ConnectionHandler.getConnection(targetFollows, 'Follows_list', {
              type: 'whereIsTarget',
              inTargetOf: user.userId,
            })
          )
        }
        if (srcFollows) {
          connections.push(
            ConnectionHandler.getConnection(srcFollows, 'Follows_list', {
              type: 'whereIsSrc',
              inTargetOf: null,
            }),
            ConnectionHandler.getConnection(srcFollows, 'Follows_list', {
              type: 'whereIsSrc',
              inTargetOf: user.userId,
            })
          )
        }
        connections.forEach(x => x?.invalidateRecord())
      }

      return {
        variables,
        optimisticResponse,
        optimisticUpdater: updater,
        onCompleted: () => {},
        updater,
      }
    }
    fGet(relativeToOther).isOtherFollowsSrc
      ? commitUndo(config(-1))
      : commit(config(1))
  })
  return handleToggleFollow
}
