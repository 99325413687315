/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type StoreHomeInfoSubscriptionVariables = {
    userId: string;
};
export type StoreHomeInfoSubscriptionResponse = {
    readonly storeFollowsAggregate: {
        readonly id: string;
        readonly followsWhereIsSrcCount: number;
        readonly followsWhereIsTargetCount: number;
    };
};
export type StoreHomeInfoSubscription = {
    readonly response: StoreHomeInfoSubscriptionResponse;
    readonly variables: StoreHomeInfoSubscriptionVariables;
};



/*
subscription StoreHomeInfoSubscription(
  $userId: String!
) {
  storeFollowsAggregate(userId: $userId) {
    id
    followsWhereIsSrcCount
    followsWhereIsTargetCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "StoreFollowsAggregate",
    "kind": "LinkedField",
    "name": "storeFollowsAggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "followsWhereIsSrcCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "followsWhereIsTargetCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StoreHomeInfoSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StoreHomeInfoSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c6f0f5122d79986705a6136f0958a4e6",
    "id": null,
    "metadata": {},
    "name": "StoreHomeInfoSubscription",
    "operationKind": "subscription",
    "text": "subscription StoreHomeInfoSubscription(\n  $userId: String!\n) {\n  storeFollowsAggregate(userId: $userId) {\n    id\n    followsWhereIsSrcCount\n    followsWhereIsTargetCount\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b94545eb34d2ba7d632b61990447af4a';
export default node;
