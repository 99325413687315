import React, {ReactElement, Suspense} from 'react'
import {useBasicStoreLive} from '../../../../Common/Page/WithBasicStoreLive'
import {CenteredCircleProgress} from '../../../../Common/Tools/CenteredCircleProgress'
import {useUser} from '../../../../Common/WithUser'
import {PrimaryActionFloatMenu} from '../../../Common/PrimaryActionFloatMenu'
import {StoreHomeInfo} from './StoreHomeInfo'
import {StoreHomeIntroduction} from './StoreHomeIntroduction'
import {StoreHomeMainButton} from './StoreHomeMainButton/StoreHomeMainButton'
import {StoreHomeStripeAlert} from './StoreHomeStripeAlert'
import {StoreHomeTab} from './StoreHomeTab'

export const StoreHomeBase = React.memo(
  ({
    children,
    currentTab,
  }: {
    children: ReactElement
    currentTab: 'timeline' | 'books' | 'posts'
  }) => {
    const store = useBasicStoreLive()
    const user = useUser()
    const isOwnStore = store.user.userId === user?.userId

    return (
      <div className="body-grid3">
        <div
          className="body-grid-content grid h-full "
          style={{grid: 'auto auto 1fr / auto'}}
        >
          <div className=" z-10 relative">
            <StoreHomeStripeAlert className="" />
            <StoreHomeMainButton className="" />
            <StoreHomeInfo className="pt-2 pb-4" />
            <StoreHomeIntroduction
              className="mb-6"
              adminEmptyText={isOwnStore}
            />
          </div>
          <StoreHomeTab className="z-10" currentTab={currentTab} />
          <div className="relative pb-16">
            <Suspense fallback={<CenteredCircleProgress />}>
              {children}
            </Suspense>
          </div>
        </div>
        <div className="body-grid-footer-positioned">
          <PrimaryActionFloatMenu />
        </div>
      </div>
    )
  }
)
