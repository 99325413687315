import {API} from '@indieocean/apidef'
import {assertFalse, noCase} from '@indieocean/utils'
import Link from 'next/link'
import React, {useMemo} from 'react'
import {graphql, useSubscription} from 'react-relay'
import {useBasicStoreLive} from '../../../../Common/Page/WithBasicStoreLive'
import {useAppPathFn} from '../../../../Common/Tools/UseAppPathFn'
import {useUser} from '../../../../Common/WithUser'
import {Book} from '../../Book/Home/Book'
import {StoreHomeInfoSubscription} from './__generated__/StoreHomeInfoSubscription.graphql'

const subscription = graphql`
  subscription StoreHomeInfoSubscription($userId: String!) {
    storeFollowsAggregate(userId: $userId) {
      id
      followsWhereIsSrcCount
      followsWhereIsTargetCount
    }
  }
`

export const StoreHomeInfo = React.memo(
  ({className = ''}: {className?: string}) => {
    const pathFn = useAppPathFn()
    const basicStore = useBasicStoreLive()
    const user = useUser()
    const store = useBasicStoreLive()
    const isOwnStore = store.user.userId === user?.userId
    const storePath = API.Path.store(store.slug)

    const {aggregate} = basicStore.data.follows
    const relativeToOther =
      'relativeToOther' in basicStore.data.follows
        ? basicStore.data.follows.relativeToOther
        : undefined

    const storeUserId = basicStore.user.userId
    useSubscription<StoreHomeInfoSubscription>(
      useMemo(
        () => ({subscription, variables: {userId: storeUserId}}),
        [storeUserId]
      )
    )

    return (
      <div className={`${className} w-full`}>
        <h1 className="">
          <span className="font-semibold">@{basicStore.slug} </span>
          {relativeToOther?.isOtherFollowsTarget && (
            <span className="ml-2">follows you</span>
          )}
        </h1>

        <div
          className={` grid gap-y-1 gap-x-2 mt-1`}
          style={{
            gridTemplate: ` "count1 label1 detail1 " auto
                            "count2 label2 detail2 " auto
                            /auto auto 1fr `,
          }}
        >
          <Link href={pathFn(storePath.followers())}>
            <a className=" text-right" style={{gridArea: 'count1'}}>
              {aggregate.followsWhereIsTargetCount}
            </a>
          </Link>
          <Link href={pathFn(storePath.followers())}>
            <a className=" " style={{gridArea: 'label1'}}>
              {aggregate.followsWhereIsTargetCount === 1
                ? 'follower'
                : 'followers'}
            </a>
          </Link>
          {relativeToOther &&
            !isOwnStore &&
            aggregate.followsWhereIsTargetCount > 0 && (
              <Link href={pathFn(storePath.followers({followedbyme: ''}))}>
                <a className="lighten-2 ml-2 " style={{gridArea: 'detail1'}}>
                  (
                  {_countOrNone(
                    relativeToOther.selfFollowSrcsInOtherFollowTargets
                  )}{' '}
                  followed by you)
                </a>
              </Link>
            )}
          <Link href={pathFn(storePath.following())}>
            <a className="text-right" style={{gridArea: 'count2'}}>
              {aggregate.followsWhereIsSrcCount}
            </a>
          </Link>
          <Link href={pathFn(storePath.following())}>
            <a className="" style={{gridArea: 'label2'}}>
              following
            </a>
          </Link>
          {relativeToOther &&
            !isOwnStore &&
            aggregate.followsWhereIsSrcCount > 0 && (
              <Link href={pathFn(storePath.following({followedbyme: ''}))}>
                <a className="lighten-2 ml-2 " style={{gridArea: 'detail2'}}>
                  (
                  {_countOrNone(
                    relativeToOther.selfFollowTargetsInOtherFollowTargets
                  )}{' '}
                  followed by you)
                </a>
              </Link>
            )}
        </div>
      </div>
    )
  }
)

const _countOrNone = (n: number) => (n === 0 ? 'none' : `${n}`)

