import { API } from '@indieocean/apidef'
import React, { ReactNode } from 'react'
import { useBasicStoreLive } from '../../../../Common/Page/WithBasicStoreLive'
import { AppLink, appPath } from '../../../../Common/Tools/AppLink'

export const StoreHomeTab = React.memo(
  ({
    className = '',
    currentTab,
  }: {
    className?: string
    currentTab: 'timeline' | 'posts' | 'books'
  }) => {
    const store = useBasicStoreLive()

    const storePath = appPath.store(store.slug)
    return (
      <div
        className={`${className} sticky top-[4.31rem] grid z-20  bg-pageBG `}
        style={{grid: '2.52rem / 1fr 1fr 1fr'}}
      >
        <_Link
          isActive={currentTab === 'timeline'}
          path={storePath.root}
          className="rounded-l-full"
        >
          Timeline
        </_Link>
        <_Link isActive={currentTab === 'books'} path={storePath.books()}>
          Books
        </_Link>
        <_Link
          isActive={currentTab === 'posts'}
          path={storePath.posts()}
          className="rounded-r-full border-r"
        >
          Posts
        </_Link>
      </div>
    )
  }
)

const _Link = ({
  isActive,
  className = '',
  path,
  children,
}: {
  isActive: boolean
  path: API.Path
  children: ReactNode
  className?: string
}) => (
  <AppLink
    className={`${className} 
    flex flex-col justify-center text-center text-sm font-semibold border-l border-t border-b border-sectionDarkBG 
    ${isActive ? 'bg-sectionDarkBG text-sectionDarkFG' : ''}`}
    path={isActive ? null : path}
  >
    {children}
  </AppLink>
)
