import { API } from '@indieocean/apidef'
import { fGet } from '@indieocean/utils'
import React from 'react'
import { ConfirmAlert } from '../../../../../Common/Modal/ConfirmAlert'
import { useBasicStoreLive } from '../../../../../Common/Page/WithBasicStoreLive'
import { MenuButton } from '../../../../../Common/Tools/MenuButton'
import { User, useUser } from '../../../../../Common/WithUser'
import { useBooleanStateObj } from '../../../../../Utils/UseBooleanStateObj'
import { useToggleStoreFollow } from './UseToggleStoreFollow'

export const StoreHomeMainButton = React.memo(
  ({className = ''}: {className?: string}) => {
    const store = useBasicStoreLive()
    const user = useUser()
    const button = !user ? (
      <_Follow />
    ) : user.userId !== store.user.userId ? (
      fGet(store.data.follows.relativeToOther).isOtherFollowsSrc ? (
        <_Unfollow user={user} />
      ) : (
        <_Follow />
      )
    ) : (
      <_EditProfile />
    )

    return <div className={`${className} flex justify-end`}>{button}</div>
  }
)

const _EditProfile = React.memo(() => {
  const store = useBasicStoreLive()
  return (
    <MenuButton className="btn-sm btn-outline" origin="bottomEnd" overlap>
      Edit Info
      {[
        {
          action: API.Path.account.editProfile,
          text: 'Edit Profile',
        },
        {
          action: API.Path.store(store.slug).editIntroduction,
          text: 'Edit Introduction',
        },
        {
          action: API.Path.account.setTwitterUsername,
          text: store.data.links.twitter?.username
            ? 'Edit Twitter Username'
            : 'Add Twitter Username',
        },
      ]}
    </MenuButton>
  )
})

const _Follow = React.memo(() => {
  const handleFollow = useToggleStoreFollow()
  return (
    <button className="btn-outline btn-sm" onClick={handleFollow}>
      Follow
    </button>
  )
})

const _Unfollow = React.memo(({user}: {user: User}) => {
  const store = useBasicStoreLive()
  const hover = useBooleanStateObj(false)
  const confirmUnfollow = useBooleanStateObj(false)
  const handleToggleFollow = useToggleStoreFollow()
  return (
    <>
      <button
        className={`${hover.value ? 'btn-outline' : 'btn-dark'}
                  btn-sm flex flex-col justify-center items-center`}
        onClick={confirmUnfollow.setTrue}
        onMouseOver={hover.setTrue}
        onMouseOut={hover.setFalse}
      >
        <span className={hover.value ? 'opacity-0' : ''}>Following</span>
        <span className={hover.value ? 'absolute' : 'hidden'}>Unfollow</span>
      </button>
      <ConfirmAlert
        open={confirmUnfollow.value}
        title={`Unfollow ${store.name}?`}
        confirmText="Unfollow"
        onCancel={confirmUnfollow.setFalse}
        onConfirm={() => {
          confirmUnfollow.setTrue()
          handleToggleFollow()
        }}
      >
        Are you sure you want to unfollow {store.name}?
      </ConfirmAlert>
    </>
  )
})
