import React, { ReactElement } from 'react'

export const StoreHomeItem = React.memo(
  ({
    className = '',
    id,
    children: [left, right],
    index,
  }: {
    className?: string
    id?: string
    children: [ReactElement, ReactElement]
    index: number
  }) => {
    return (
      // Earlier elements are above later ones to fix overlapping issue caused
      // by negative margin trick for iOS scrollTo support.
      <div
        className={`${className} w-full relative`}
        style={{zIndex: 1000000 - index}}
      >
        <div
          // Negative margin trick because iOS does not support scrollMarginTop.
          className={` w-full grid gap-x-4 mt-[-10.83rem] pt-[10.83rem]`}
          id={id}
          //minmax(0px, 1fr) instead of 1fr because of long text (e.g. in links):
          // https://stackoverflow.com/questions/52861086
          style={{
            grid: 'auto/ auto minmax(0px, 1fr)',
          }}
        >
          {left}
          {right}
        </div>
      </div>
    )
  }
)
