import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import AutoLinker from 'autolinker'
import _ from 'lodash'
import React from 'react'
import {useBasicStoreLive} from '../../../../Common/Page/WithBasicStoreLive'

const _adminModeEmptyText = `You don't have an introduction to your store yet. Click "Edit Info" above to add an introduction.`
export const StoreHomeIntroduction = React.memo(
  ({
    className = '',
    adminEmptyText,
  }: {
    className?: string
    adminEmptyText: boolean
  }) => {
    const store = useBasicStoreLive()

    const introduction =
      store.data.introduction ?? (adminEmptyText ? _adminModeEmptyText : '')

    if (introduction === '') return <></>
    return (
      <div className={`${className} flex flex-col justify-center items-start`}>
        {(introduction ?? '')
          .split('\n')
          .map(x => x.trim())
          .map((x, i) => (
            <p
              className={`font-karla ${x.length === 0 ? 'min-h-[.75em]' : ''}`}
              key={i}
            >
              {splitText(x).map((x, i) => {
                switch (x.type) {
                  case 'string':
                    return <span key={i}>{x.value}</span>
                  case 'url':
                    return (
                      <a
                        className="underline"
                        key={i}
                        href={x.url}
                        target="_blank"
                      >
                        {x.value}
                      </a>
                    )
                }
              })}
            </p>
          ))}
        {store.data.links.twitter && (
          <a
            className="inline-block mt-3"
            href={`https://twitter.com/${store.data.links.twitter.username}`}
            target="_blank"
          >
            <FontAwesomeIcon className="mr-2" icon={faTwitter} />
            {store.data.links.twitter.username}
          </a>
        )}
      </div>
    )
  }
)

function splitText(text: string) {
  const matches = AutoLinker.parse(text, {
    urls: true,
    email: false,
    phone: false,
    mention: false,
    hashtag: false,
    stripPrefix: {scheme: true, www: false},
  })
  const result: (
    | {type: 'string'; value: string}
    | {type: 'url'; value: string; url: string}
  )[] = []
  matches.forEach((match, i) => {
    const prevEnd =
      i === 0
        ? 0
        : matches[i - 1].getOffset() + matches[i - 1].getMatchedText().length
    result.push({
      type: 'string',
      value: text.substring(prevEnd, match.getOffset()),
    })

    switch (match.getType()) {
      case 'url':
        result.push({
          type: 'url',
          value: match.getAnchorText(),
          url: match.getAnchorHref(),
        })
        break
      default:
        throw new Error()
    }
  })

  const lastMatch = _.last(matches)

  result.push({
    type: 'string',
    value: text.substr(
      lastMatch ? lastMatch.getOffset() + lastMatch.getMatchedText().length : 0
    ),
  })

  return result
}
